<template>
  <CModal
    class="sbx-invoice-results-details-modal modal-xxl"
    :show.sync="modalShow"
    size="xl"
    centered
    :close-on-backdrop="false"
  >
    <template #header>
      <div class="d-flex w-100">
        <h2>Invoice: {{ invoiceNumber }}</h2>
        <MRecordStatusBadge
          :value="recordStatus"
          class="h2"
          style="margin-left: auto; margin-right: 0"
        />
      </div>
    </template>
    <CCard class="document-details-card sbx-document-details-card">
      <CCardBody>
        <CRow>
          <CCol>
            <CCallout color="primary">
              <small class="text-muted">External Company ID</small><br />
              <strong class="h4">{{ externalCompanyId }}</strong>
            </CCallout>
          </CCol>
          <CCol>
            <CCallout :color="companyName ? 'primary' : 'secondary'">
              <small class="text-muted">Company Name</small><br />
              <span class="h4" :class="{ 'text-muted': !companyName }">
                {{ companyName || '--' }}
              </span>
            </CCallout>
          </CCol>
          <CCol>
            <CCallout color="primary">
              <small class="text-muted">Company Role</small><br />
              <strong class="h4">{{ companyRole }}</strong>
            </CCallout>
          </CCol>
        </CRow>
        <CRow class="pt-2">
          <CCol col="4">
            <fieldset class="d-inline-flex">
              <legend>General</legend>
              <LabeledValue label="Invoice Date">
                {{ invoiceDate }}
              </LabeledValue>
              <LabeledValue label="Currency Code">
                {{ currencyCode }}
              </LabeledValue>

              <LabeledValue label="Transaction Type">
                {{ transactionType }}
              </LabeledValue>
              <LabeledValue v-if="titleTransfer" label="Title Transfer">
                {{ titleTransfer }}
              </LabeledValue>
            </fieldset>
          </CCol>
          <CCol>
            <SbxRegistrationsBlock v-bind="registrations" />
          </CCol>
          <CCol>
            <SbxCounterPartyBlock v-bind="counterParty" />
          </CCol>
        </CRow>

        <CTabs :active-tab.sync="activeTab" class="pt-5">
          <CTab title="Invoice" class="invoice-details-tab">
            <CCard class="no-border">
              <CCardBody>
                <CRow class="pb-4">
                  <CCol>
                    <CRow>
                      <CCol><h4>Invoice Level Validations</h4></CCol>
                    </CRow>
                    <CRow v-if="hasInvoiceValidations">
                      <CCol
                        v-for="(v, vIdx) in validationResults"
                        :key="`${vIdx}_${v.name}`"
                        col="4"
                      >
                        <SbxValidationResult :value="v" />
                      </CCol>
                    </CRow>
                    <CRow v-else>
                      <CCol>
                        <div class="text-muted h4 mt-2 ml-1">
                          None <small class="ml-1">(see lines)</small>
                        </div>
                      </CCol>
                    </CRow>
                  </CCol>
                </CRow>
                <CRow v-if="hasVCT">
                  <CCol>
                    <h4>Vendor Charged Tax</h4>
                    <div class="w-100 d-flex">
                      <CCallout color="secondary">
                        <small class="text-muted">Vendor Tax</small><br />
                        <strong class="h4">{{
                          $format.currency(currencyCode, vct.vendorTax) || '--'
                        }}</strong>
                      </CCallout>
                      <CCallout color="secondary" class="ml-5">
                        <small class="text-muted">Response Code</small><br />
                        <strong class="h4">{{ vct.responseCode }}</strong>
                      </CCallout>
                      <CCallout
                        class="ml-5"
                        :color="
                          vct.status == 'OC' || vct.status == 'UC'
                            ? 'primary'
                            : 'secondary'
                        "
                      >
                        <small class="text-muted">Status</small><br />
                        <strong class="h4">{{ vct.status }}</strong>
                      </CCallout>
                      <CCallout color="secondary" class="ml-5">
                        <small class="text-muted">Company Role</small><br />
                        <strong class="h4">{{
                          vct.companyRole || '--'
                        }}</strong>
                      </CCallout>
                      <CCallout
                        :color="!vct.variance ? 'secondary' : 'primary'"
                        class="ml-5"
                      >
                        <small class="text-muted">Variance</small><br />
                        <strong class="h4">{{
                          $format.currency(currencyCode, vct.variance) || '--'
                        }}</strong>
                      </CCallout>
                    </div>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol>
                    <div class="w-100 d-flex">
                      <span class="h4">Invoice Input Properties</span>
                      <MCollapseToggler :value.sync="showInvoiceInput" />
                    </div>
                    <hr />
                  </CCol>
                </CRow>
                <CCollapse :show="showInvoiceInput">
                  <CRow>
                    <CCol>
                      <CCard>
                        <CCardBody>
                          <CRow v-if="hasInvoiceUserElements" class="pb-4">
                            <CCol v-if="hasInvoiceUserElements" col="6">
                              <fieldset>
                                <legend>User Elements</legend>
                                <div class="user-element-container">
                                  <LabeledValue
                                    v-for="e in invoiceUserElements"
                                    :key="e.key"
                                    :label="e.label"
                                    :value="e.value"
                                  />
                                </div>
                              </fieldset>
                            </CCol>
                          </CRow>
                          <CRow v-if="hasInvoiceAddresses" class="pb-4">
                            <CCol>
                              <fieldset>
                                <legend>
                                  Addresses
                                  <small class="text-muted">
                                    ({{ invoiceAddresses.length }})
                                  </small>
                                </legend>
                                <CRow>
                                  <CCol
                                    v-for="invAddress in invoiceAddresses"
                                    :key="invAddress.key"
                                    col="6"
                                    md="4"
                                    lg="3"
                                  >
                                    <DocumentAddress
                                      :value="invAddress"
                                      :label="
                                        $t(
                                          `addressType.${invAddress.fieldName
                                            .toLowerCase()
                                            .replace('invoice.', '')}`,
                                        )
                                      "
                                      class="pt-2"
                                    />
                                  </CCol>
                                </CRow>
                              </fieldset>
                            </CCol>
                          </CRow>
                          <CRow
                            v-if="documentAttributes.length > 0"
                            class="pb-4"
                          >
                            <CCol>
                              <fieldset>
                                <legend>All Other Invoice Attributes</legend>
                                <div
                                  v-if="documentAttributes.length > 0"
                                  class="user-element-container"
                                >
                                  <LabeledValue
                                    v-for="e in documentAttributes"
                                    :key="e.key"
                                    :label="e.label"
                                  >
                                    <MCheckIcon
                                      v-if="e.fieldType === 'BOOLEAN'"
                                      :value="e.value"
                                      show-false
                                    />
                                    <span v-else>
                                      {{ e.value }}
                                    </span>
                                  </LabeledValue>
                                </div>
                                <div v-else class="text-muted h4 mt-2 ml-1">
                                  None
                                </div>
                              </fieldset>
                            </CCol>
                          </CRow>
                        </CCardBody>
                      </CCard>
                    </CCol>
                  </CRow>
                </CCollapse>
              </CCardBody>
            </CCard>
          </CTab>

          <CTab :title="`Lines (${lineCount})`" class="line-details-tab">
            <CTabs
              :active-tab="activeLineTab"
              class="pt-4"
              variant="pills"
              :vertical="{
                navs: 'col-xs-2 col-md-1',
                content: 'col-xs-10 col-md-11',
              }"
            >
              <CTab
                v-for="(line, index) in documentLines"
                :key="`${index}_${line.lineNumber}_${line.key}`"
                :title="`Line ${line.lineNumber}`"
              >
                <SbxInvoiceLineResultTab :line="line" />
              </CTab>
            </CTabs>
          </CTab>

          <CTab title="XML" class="xml-tab">
            <CCard>
              <CCardBody>
                <CRow>
                  <CCol col="6" style="position: relative">
                    <h4>Request XML</h4>
                    <MClipboardButton
                      id="btn-sbx-invoice-details-request-copy"
                      style="
                        margin-right: 1rem;
                        position: absolute;
                        top: 5px;
                        right: 25px;
                      "
                      @click="copyTextToClipBoard"
                    />
                    <pre
                      :id="xmlDetailsId"
                      class="code hljs xml"
                      v-html="documentXML.highlight"
                    ></pre>
                  </CCol>
                  <CCol col="6" style="position: relative">
                    <h4>Response XML</h4>
                    <MClipboardButton
                      id="btn-sbx-invoice-details-response-copy"
                      style="
                        margin-right: 1rem;
                        position: absolute;
                        top: 5px;
                        right: 25px;
                      "
                      @click="copyResponseToClipBoard"
                    />
                    <pre
                      :id="xmlResponseDetailsId"
                      class="code hljs xml"
                      v-html="responseXML.highlight"
                    ></pre>
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          </CTab>
        </CTabs>
      </CCardBody>
    </CCard>
    <template #footer>
      <CButton
        id="btn-sbx-invoice-results-details-close"
        color="primary"
        @click="modalShow = false"
        >Close</CButton
      >
    </template>
  </CModal>
</template>
<script>
import DocumentAddress from '@/components/DocumentAddress';
import SbxRegistrationsBlock from '@/views/testing/decks/sbx/_components/SbxRegistrationsBlock';
import SbxCounterPartyBlock from '@/views/testing/decks/sbx/_components/SbxCounterPartyBlock';
import SbxInvoiceLineResultTab from './SbxInvoiceLineResultTab';
import { MClipboardButton } from '@/components/Buttons';
import MRecordStatusBadge from '@/components/MRecordStatusBadge';
import { parseValue, highlightXML } from './SbxInvoiceResultsDetailsUtils';
import SbxValidationResult from '../SbxValidationResult';

export default {
  name: 'SbxInvoiceResultsDetailsModal',
  components: {
    SbxInvoiceLineResultTab,
    DocumentAddress,
    SbxRegistrationsBlock,
    SbxCounterPartyBlock,
    MClipboardButton,
    MRecordStatusBadge,
    SbxValidationResult,
  },
  props: {
    show: {
      type: Boolean,
      required: false,
    },
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showInvoiceInput: false,
      activeTab: 0,
      activeLineTab: 0,
      registrations: [],
      counterParty: null,
      invoiceNumber: null,
      companyName: null,
      externalCompanyId: null,
      companyRole: null,
      transactionType: null,
      invoiceDate: null,
      titleTransfer: null,
      currencyCode: null,
      invoiceUserElements: [],
      hasOtherElements: false,
      invoiceAddresses: [],
      documentAttributes: [],
      documentLines: [],
      documentXML: '',
      responseXML: '',
      recordStatus: null,
      totalTaxAmount: 0,
      taxSummary: null,
      validationResults: [],
      attributeFields: Object.freeze([
        {
          key: 'label',
          label: 'Name',
        },
        {
          key: 'fieldPath',
          label: 'Path',
          hidden: true,
        },
        {
          key: 'value',
          label: 'Value',
          filter: false,
          sorter: false,
        },
      ]),
    };
  },
  computed: {
    vct() {
      const details = this.value?._details;
      if (
        details?.vctCalcCompanyRole ||
        details?.vctResponseCode ||
        details?.vctStatus ||
        details?.vctTaxVariance
      ) {
        return {
          companyRole: details.vctCalcCompanyRole,
          responseCode: details.vctResponseCode,
          status: details.vctStatus,
          variance: details.vctTaxVariance,
          vendorTax: details.vendorTax,
        };
      } else {
        return null;
      }
    },
    hasVCT() {
      return !!this.vct;
    },
    xmlDetailsId() {
      return `sbx-xml-result-details_${this.value.id}`;
    },
    xmlResponseDetailsId() {
      return `sbx-xml-result-response-details_${this.value.id}`;
    },
    modalShow: {
      get() {
        return this.show === true;
      },
      set(v) {
        this.$emit('update:show', v);
      },
    },
    hasRegistrations() {
      return !!(
        this.registrations?.buyer ||
        this.registrations?.seller ||
        this.registrations?.middleman
      );
    },
    hasInvoiceAddresses() {
      return this.invoiceAddresses.length > 0;
    },
    lineCount() {
      return this.value.lineCount || this.value._details.lineCount;
    },

    hasInvoiceUserElements() {
      return this.invoiceUserElements && this.invoiceUserElements.length > 0;
    },
    hasInvoiceValidations() {
      return this.validationResults?.length > 0;
    },

    hasCounterPartyInfo() {
      return !!this.counterParty;
    },
  },
  mounted() {
    const data = parseValue(this.value);
    this.registrations = data.registrations;
    this.counterParty = data.counterParty;
    this.companyName = data.companyName;
    this.invoiceNumber = data.invoiceNumber;
    this.externalCompanyId = data.externalCompanyId;
    this.companyRole = data.companyRole;
    this.transactionType = data.transactionType;
    this.invoiceDate = data.invoiceDate;
    this.titleTransfer = data.titleTransfer;
    this.currencyCode = data.currencyCode;
    this.invoiceUserElements = data.invoiceUserElements;
    this.documentAttributes = data.documentAttributes;
    this.invoiceAddresses = data.invoiceAddresses;
    this.documentLines = data.documentLines;
    this.documentXML = Object.freeze({
      plain: data.documentXML,
      highlight: highlightXML(data.documentXML),
    });
    if (data.responseXML) {
      try {
        this.responseXML = Object.freeze({
          plain: data.responseXML,
          highlight: highlightXML(data.responseXML),
        });
      } catch (err) {
        this.$log.error('Error parsing response XML', err);
        this.responseXML = Object.freeze({
          plain: '',
          highlight: '<h4 class="text-muted">No response XML</h4>',
        });
      }
    } else {
      this.responseXML = Object.freeze({
        plain: '',
        highlight: '<h4 class="text-muted">No response XML</h4>',
      });
    }
    this.recordStatus = data.recordStatus;
    this.totalTaxAmount = data.totalTaxAmount;
    this.taxSummary = data.taxSummary;
    this.validationResults = data.validationResults;
    this.showInvoiceInput = data.validationResults.length < 1;
  },
  methods: {
    copyTextToClipBoard() {
      const copyText = document.createElement('textarea');
      const parent = document.getElementById(this.xmlDetailsId);
      copyText.value = this.documentXML.plain;
      copyText.setAttribute('readonly', 'true');
      copyText.style = { visibility: 'hidden' };
      parent.appendChild(copyText);
      copyText.select();
      navigator.clipboard.writeText(copyText.value);
      parent.removeChild(copyText);
    },
    copyResponseToClipBoard() {
      if (this.responseXML && this.responseXML.plain) {
        const copyText = document.createElement('textarea');
        const parent = document.getElementById(this.xmlResponseDetailsId);
        copyText.value = this.responseXML.plain;
        copyText.setAttribute('readonly', 'true');
        copyText.style = { visibility: 'hidden' };
        parent.appendChild(copyText);
        copyText.select();
        navigator.clipboard.writeText(copyText.value);
        parent.removeChild(copyText);
      }
    },
  },
};
</script>
