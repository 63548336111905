<template>
  <CCard class="line-tax-details-card">
    <CCardHeader>
      <CRow class="pb-2 tax-title-row">
        <CCol>
          <div class="w-100 d-flex">
            <div class="h5">
              <span class="pr-1"
                ><strong>{{ taxIndex }}.</strong></span
              >
              <strong
                >{{
                  $enum('jurisdictionLevelCode', lineTax.jurisdictionLevel)
                }}: {{ lineTax.jurisdiction }}</strong
              >
            </div>

            <div>
              <CBadge
                :color="getTaxResultColor(lineTax.taxResult)"
                class="ml-3"
                >{{ $enum('taxResultCode', lineTax.taxResult) }}</CBadge
              >
            </div>
          </div>
        </CCol>
      </CRow>
    </CCardHeader>
    <CCardBody>
      <CRow>
        <CCol>
          <CRow>
            <CCol>
              <fieldset>
                <legend>Tax Authority</legend>
                <div class="d-flex w-100">
                  <LabeledValue
                    label="Situs"
                    :value="$enum('taxingLocationType', lineTax.situs)"
                  />
                  <LabeledValue
                    label="Tax Collected From"
                    :value="$enum('companyRole', lineTax.taxCollectedFromParty)"
                  />
                  <LabeledValue
                    label="Tax Type"
                    :value="$enum('taxingType', lineTax.taxType)"
                  />
                  <LabeledValue
                    v-show="!!lineTax.rateClassification"
                    label="Rate Classification"
                    :value="lineTax.rateClassification || '--'"
                  />
                  <LabeledValue
                    v-show="!!lineTax.reasonCode"
                    label="Reason Code"
                    :value="lineTax.reasonCode || '--'"
                  />
                </div>
                <div class="d-flex w-100">
                  <LabeledValue
                    label="Jurisdiction Level"
                    :value="
                      $enum('jurisdictionLevelCode', lineTax.jurisdictionLevel)
                    "
                  />
                  <LabeledValue
                    label="Jurisdiction"
                    :value="lineTax.jurisdiction"
                  />
                  <LabeledValue
                    label="Jurisdiction ID"
                    :value="lineTax.jurisdictionId"
                  />
                </div>
              </fieldset>
            </CCol>
            <CCol>
              <CRow
                v-if="
                  !!lineTax.buyerRegistration || !!lineTax.sellerRegistration
                "
                class="mt-2"
              >
                <CCol>
                  <fieldset>
                    <legend>Tax Registrations</legend>
                    <div class="w-100 d-flex">
                      <LabeledValue
                        label="Buyer"
                        :value="lineTax.buyerRegistration || '--'"
                      />
                      <LabeledValue
                        label="Seller"
                        :value="lineTax.sellerRegistration || '--'"
                      />
                    </div>
                  </fieldset>
                </CCol>
              </CRow>
            </CCol>
          </CRow>

          <CRow class="pb-2">
            <CCol>
              <fieldset>
                <legend>Tax Properties</legend>
                <CRow>
                  <CCol>
                    <div class="d-flex w-100">
                      <LabeledValue
                        label="Exempt"
                        :value="lineTax.exempt ? 'Yes' : 'No'"
                      />
                      <LabeledValue
                        label="Imposition Type"
                        :value="lineTax.impositionType || '--'"
                      />
                      <LabeledValue
                        label="Imposition"
                        :value="lineTax.imposition || '--'"
                      />

                      <LabeledValue
                        v-show="!!lineTax.inputOutputType"
                        label="I/O Type"
                        :value="
                          $enum(
                            'inputOutputCodeType',
                            lineTax.inputOutputType,
                          ) || '--'
                        "
                      />
                      <LabeledValue
                        label="Tax Structure"
                        :value="
                          $enum('taxStructureCodeType', lineTax.taxStructure) ||
                          '--'
                        "
                      />
                    </div>

                    <LabeledValue
                      v-show="!!lineTax.basisReductionPercentage"
                      label="Basis Reduction"
                      :value="
                        $format.percent(lineTax.basisReductionPercentage, 4) ||
                        '--'
                      "
                    />
                  </CCol>
                </CRow>
                <CRow>
                  <CCol>
                    <div class="d-flex w-100">
                      <LabeledValue
                        label="Calculated Tax"
                        :value="
                          $format.currency(
                            currencyCode,
                            lineTax.calculatedTax,
                            2,
                          ) || '--'
                        "
                      />
                      <LabeledValue
                        label="Effective Rate"
                        :value="
                          $format.percent(lineTax.effectiveRate, 4) || '--'
                        "
                      />

                      <LabeledValue
                        label="Nominal Rate"
                        :value="$format.percent(lineTax.nominalRate, 4) || '--'"
                      />
                      <LabeledValue
                        label="Tax Code"
                        :value="lineTax.taxCode || '--'"
                      />

                      <LabeledValue
                        label="Vertex Tax Code"
                        :value="lineTax.vertexTaxCode || '--'"
                      />
                      <LabeledValue
                        label="Filing Category"
                        :value="lineTax.filingCategoryCode || '--'"
                      />
                    </div>
                    <div class="d-flex w-100">
                      <LabeledValue
                        label="Tax Rule Type"
                        :value="
                          $enum('taxRuleCodeType', lineTax.taxRuleType) || '--'
                        "
                      />

                      <LabeledValue
                        label="Tax Rule ID"
                        :value="lineTax.taxRuleId || '--'"
                      />

                      <LabeledValue
                        label="Summary Invoice Text"
                        style="max-width: 300px"
                      >
                        <span class="text-small">{{
                          lineTax.summaryInvoiceText || '--'
                        }}</span>
                      </LabeledValue>
                    </div>
                  </CCol>
                </CRow>
              </fieldset>
            </CCol>
          </CRow>
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>
<script>
export default {
  name: 'VtxLineTaxResult',
  props: {
    lineTax: {
      type: Object,
      required: true,
    },
    line: {
      type: Object,
      required: false,
      default() {
        return null;
      },
    },
    taxIndex: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    currencyCode() {
      return this.line?.currencyCode || null;
    },
  },
  methods: {
    getTaxResultColor(taxResult) {
      switch (taxResult?.toUpperCase()) {
        case 'TAXABLE':
        case 'DPPAPPLIED':
          return 'info';
        case 'EXEMPT':
          return 'warning';
        default:
          return 'secondary';
      }
    },
  },
};
</script>
