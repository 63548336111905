var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CCard',{staticClass:"line-tax-details-card"},[_c('CCardHeader',[_c('CRow',{staticClass:"pb-2 tax-title-row"},[_c('CCol',[_c('div',{staticClass:"w-100 d-flex"},[_c('div',{staticClass:"h5"},[_c('span',{staticClass:"pr-1"},[_c('strong',[_vm._v(_vm._s(_vm.taxIndex)+".")])]),_c('strong',[_vm._v(_vm._s(_vm.$enum('jurisdictionLevelCode', _vm.lineTax.jurisdictionLevel))+": "+_vm._s(_vm.lineTax.jurisdiction))])]),_c('div',[_c('CBadge',{staticClass:"ml-3",attrs:{"color":_vm.getTaxResultColor(_vm.lineTax.taxResult)}},[_vm._v(_vm._s(_vm.$enum('taxResultCode', _vm.lineTax.taxResult)))])],1)])])],1)],1),_c('CCardBody',[_c('CRow',[_c('CCol',[_c('CRow',[_c('CCol',[_c('fieldset',[_c('legend',[_vm._v("Tax Authority")]),_c('div',{staticClass:"d-flex w-100"},[_c('LabeledValue',{attrs:{"label":"Situs","value":_vm.$enum('taxingLocationType', _vm.lineTax.situs)}}),_c('LabeledValue',{attrs:{"label":"Tax Collected From","value":_vm.$enum('companyRole', _vm.lineTax.taxCollectedFromParty)}}),_c('LabeledValue',{attrs:{"label":"Tax Type","value":_vm.$enum('taxingType', _vm.lineTax.taxType)}}),_c('LabeledValue',{directives:[{name:"show",rawName:"v-show",value:(!!_vm.lineTax.rateClassification),expression:"!!lineTax.rateClassification"}],attrs:{"label":"Rate Classification","value":_vm.lineTax.rateClassification || '--'}}),_c('LabeledValue',{directives:[{name:"show",rawName:"v-show",value:(!!_vm.lineTax.reasonCode),expression:"!!lineTax.reasonCode"}],attrs:{"label":"Reason Code","value":_vm.lineTax.reasonCode || '--'}})],1),_c('div',{staticClass:"d-flex w-100"},[_c('LabeledValue',{attrs:{"label":"Jurisdiction Level","value":_vm.$enum('jurisdictionLevelCode', _vm.lineTax.jurisdictionLevel)}}),_c('LabeledValue',{attrs:{"label":"Jurisdiction","value":_vm.lineTax.jurisdiction}}),_c('LabeledValue',{attrs:{"label":"Jurisdiction ID","value":_vm.lineTax.jurisdictionId}})],1)])]),_c('CCol',[(
                !!_vm.lineTax.buyerRegistration || !!_vm.lineTax.sellerRegistration
              )?_c('CRow',{staticClass:"mt-2"},[_c('CCol',[_c('fieldset',[_c('legend',[_vm._v("Tax Registrations")]),_c('div',{staticClass:"w-100 d-flex"},[_c('LabeledValue',{attrs:{"label":"Buyer","value":_vm.lineTax.buyerRegistration || '--'}}),_c('LabeledValue',{attrs:{"label":"Seller","value":_vm.lineTax.sellerRegistration || '--'}})],1)])])],1):_vm._e()],1)],1),_c('CRow',{staticClass:"pb-2"},[_c('CCol',[_c('fieldset',[_c('legend',[_vm._v("Tax Properties")]),_c('CRow',[_c('CCol',[_c('div',{staticClass:"d-flex w-100"},[_c('LabeledValue',{attrs:{"label":"Exempt","value":_vm.lineTax.exempt ? 'Yes' : 'No'}}),_c('LabeledValue',{attrs:{"label":"Imposition Type","value":_vm.lineTax.impositionType || '--'}}),_c('LabeledValue',{attrs:{"label":"Imposition","value":_vm.lineTax.imposition || '--'}}),_c('LabeledValue',{directives:[{name:"show",rawName:"v-show",value:(!!_vm.lineTax.inputOutputType),expression:"!!lineTax.inputOutputType"}],attrs:{"label":"I/O Type","value":_vm.$enum(
                          'inputOutputCodeType',
                          _vm.lineTax.inputOutputType
                        ) || '--'}}),_c('LabeledValue',{attrs:{"label":"Tax Structure","value":_vm.$enum('taxStructureCodeType', _vm.lineTax.taxStructure) ||
                        '--'}})],1),_c('LabeledValue',{directives:[{name:"show",rawName:"v-show",value:(!!_vm.lineTax.basisReductionPercentage),expression:"!!lineTax.basisReductionPercentage"}],attrs:{"label":"Basis Reduction","value":_vm.$format.percent(_vm.lineTax.basisReductionPercentage, 4) ||
                      '--'}})],1)],1),_c('CRow',[_c('CCol',[_c('div',{staticClass:"d-flex w-100"},[_c('LabeledValue',{attrs:{"label":"Calculated Tax","value":_vm.$format.currency(
                          _vm.currencyCode,
                          _vm.lineTax.calculatedTax,
                          2
                        ) || '--'}}),_c('LabeledValue',{attrs:{"label":"Effective Rate","value":_vm.$format.percent(_vm.lineTax.effectiveRate, 4) || '--'}}),_c('LabeledValue',{attrs:{"label":"Nominal Rate","value":_vm.$format.percent(_vm.lineTax.nominalRate, 4) || '--'}}),_c('LabeledValue',{attrs:{"label":"Tax Code","value":_vm.lineTax.taxCode || '--'}}),_c('LabeledValue',{attrs:{"label":"Vertex Tax Code","value":_vm.lineTax.vertexTaxCode || '--'}}),_c('LabeledValue',{attrs:{"label":"Filing Category","value":_vm.lineTax.filingCategoryCode || '--'}})],1),_c('div',{staticClass:"d-flex w-100"},[_c('LabeledValue',{attrs:{"label":"Tax Rule Type","value":_vm.$enum('taxRuleCodeType', _vm.lineTax.taxRuleType) || '--'}}),_c('LabeledValue',{attrs:{"label":"Tax Rule ID","value":_vm.lineTax.taxRuleId || '--'}}),_c('LabeledValue',{staticStyle:{"max-width":"300px"},attrs:{"label":"Summary Invoice Text"}},[_c('span',{staticClass:"text-small"},[_vm._v(_vm._s(_vm.lineTax.summaryInvoiceText || '--'))])])],1)])],1)],1)])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }